.dataTable {
  border: 1px solid $light-grey;
  border-radius: 8px;
  background: white;
  padding: 0;
  padding-top: 5px;

  overflow-x: scroll;
  table {
    width: 100%;
  }

  thead th {
    border-top: 0;
    padding: 10px;
    border-bottom: 1px solid $very-light-grey;
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      padding-right: 30px;
    }
  }

  tbody {
    tr {
      border-top: 1px solid $very-light-grey;
    }
  }

  td {
    padding: 10px;
    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      padding-right: 30px;
    }
  }
}
