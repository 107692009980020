.header {
  margin-bottom: 20px;

  .headerFlex {
    display: flex;
  }
}

.menu {
  flex-grow: 1;
}
.userMenu .ant-col {
  padding-left: 10px;
}

.headerIcon {
  color: #82878e;
  margin-top: 12px;
  font-size: 150%;
}
.currentUser {
  font-size: 16px;
  margin-top: 3px;
  margin-right: 10px;
}

.statusMessage {
  color: white;
  padding: 10px 20px;
  text-align: center;
  &.warning {
    background: $red;
  }
  &.connecting {
    background: $blue;
  }
  &.caution {
    background: $orange;
  }
}
.systemType {
  color: white;
  padding: 0px 10px;
  p {
    margin-left: 10px;
    padding: 5px 10px;
    position: relative;
    top: 10px;
    text-transform: uppercase;
    display: inline;
    border-radius: 5px;
  }
}
#test {
  background: $orange;
}
#development {
  background: $green;
}
#quality {
  background: $purple;
}

.menu-button {
  font-size: 30px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  float: right;
}

.links {
  height: 0;
  overflow: hidden;
  list-style-type: none;
  font-weight: bold;
  margin: 11px 0 0;
  a {
    font-weight: 400;
    color: $grey;
    font-size: 16px;
    margin: 0 0 0 25px;
    &:hover {
      color: $blue;
    }
    &.active {
      font-weight: 600;
      color: $dark-grey;
    }
  }
}

.show-nav {
  height: 120px;
}

/* Media screen size */
@media screen and (min-width: 992px) {
  .menu-button {
    display: none;
  }
  .links {
    height: auto;
    display: flex;
  }
  .vertical-menu {
    display: none;
  }
}
//hide the mobile menu and the mobile menu button when not mobile device
@media screen and (max-width: 992px) {
  .hide-mobile {
    display: none;
  }
  .vertical-menu {
    padding-top: 5px;
    display: block;
  }
  .links {
    display: none;
  }
}
