.h3 {
  padding-top: 1rem;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}

.divider {
  margin: 0;
  margin-top: 8px;
}

.centered-container {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.restart-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.restart-message {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #3b8bea;
}

.progress-bar {
  padding-bottom: 1rem;
}
