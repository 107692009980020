.numberChart {
  margin-top: 0;
  .number {
    text-align: center;
    h1 {
      font-weight: bold;
      margin: 0;
      line-height: 1;
    }
    &.single {
      h1 {
        font-size: 172px;
      }
    }
    &.double {
      h1 {
        font-size: 96px;
      }
    }
    &.quad {
      min-height: 150px;
      h1 {
        font-size: 64px;
      }
    }
  }
}

.widget {
  .title {
    margin-top: -4px;
  }
  .titleInput {
    font-family: "Montserrat", sans-serif;
    line-height: auto;
    font-size: 20px;
    margin: 8px 0 5px;
    font-weight: 600;
  }

  .editButton,
  .deleteButton {
    padding: 3px 6px;
    display: inline-block;
    opacity: 0.5;
    margin-left: 4px;
    &:hover {
      opacity: 1;
    }
  }
  .saveButton {
    padding: 3px 15px;
    margin-top: -25px;
  }

  .sizeToggle {
    display: inline-block;
    margin-right: 10px;
  }

  .newDatasetForm {
    display: flex;

    .snapshotSelect {
      flex-grow: 1;
    }
    .newDatasetSubmit {
      margin-left: 20px;
    }
  }
  .trendlines {
    margin: 20px 0;
    border-top: 2px solid $light-grey;
    .trendline {
      padding: 8px 0 10px;
      border-bottom: 1px solid $very-light-grey;
      a.link {
        line-height: auto;
        padding-top: 3px;
        flex-grow: 1;
        font-size: 16px;
      }
      .colorPicker {
        position: relative;
        margin-top: 3px;
      }
      .popover {
        position: absolute;
        left: -244px;
        top: 32px;
        z-index: 1;
      }
      .colorButton {
        height: 24px;
        width: 24px;
        display: block;
        border-radius: 3px;
        border: 1px solid $very-light_grey;
      }
    }
  }
  .datasetList {
    margin: 20px 0;
    border-top: 2px solid $light-grey;

    .dataset {
      padding: 8px 0 10px;
      display: flex;
      border-bottom: 1px solid $very-light-grey;
      a.link {
        line-height: auto;
        padding-top: 3px;
        flex-grow: 1;
        font-size: 16px;
      }
      .colorPicker {
        position: relative;
        margin-top: 3px;
      }
      .popover {
        position: absolute;
        left: -244px;
        top: 32px;
        z-index: 1;
      }
      .colorButton {
        height: 24px;
        width: 24px;
        display: block;
        border-radius: 3px;
        border: 1px solid $very-light_grey;
      }
    }
  }
}
