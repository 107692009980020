.group,
.reportFilters {
  background: white;
  padding: 20px 30px;
}

.group {
  padding-bottom: 0;
  border-bottom: 1px solid $very-light-grey;
}
