.reportColumn {
  border-radius: 5px;
  border: 1px solid $light-grey;
  display: flex;
  align-items: center;
  padding: 10px 24px;

  .name {
    flex-grow: 1;
    h4 {
      margin: 0;
    }
  }
  .actions {
    text-align: right;
    a {
      margin-left: 10px;
    }
  }
  .columnContainer {
    width: 100%;
    align-items: center;
  }
}
