.sideMenu {
  ul {
    padding: 0;
    margin: 0;
  }
  .box {
    ul {
      margin: -20px -30px;
    }
  }
  li {
    list-style-type: none;
  }
  a {
    display: block;
    padding: 10px 20px;
    font-size: 1.5em;
    &.active {
      color: $dark-grey;
      font-weight: bold;
      background-color: $very-light-grey;
    }
  }
}
